<template>
    <div>
        <div class="container">
            <!-- <div style="display:flex;justify-content: right;">
            <div><el-button type="text" @click="onMediaDetail(null,true)">新建方案</el-button></div>
            </div> -->
            <div v-if="active==0">
            <el-form ref="form" label-position="left" :model="curTiaojian" label-width="130px" >
                <el-row>
                    <el-form-item label="客户行业">
                        <el-select v-model="curTiaojian.industryId" style="width:213px;" placeholder="请选择客户行业">
                            <el-option v-for="item in categoryList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-row>
                <el-row v-if="curTiaojian.industryId!=''">
                    <el-form-item label="投放预算(万元)">
                        <el-input v-model="curTiaojian.budget" style="width:213px;" placeholder="请输入投放预算"
                            class="handle-input mr10"></el-input>
                    </el-form-item>
                </el-row>
                <el-row v-if="curTiaojian.budget.length>0">
                    <el-form-item label="LED占比%">
                        <!-- <el-input v-model="curTiaojian.ledPercent" style="width:213px;" 
                            class="handle-input mr10"></el-input> -->
                        <el-input-number  v-model="curTiaojian.ledPercent" :precision="1" :step="10" :min="0" :max="100" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="灯箱占比%" style="margin-left:10px;">
                        <!-- <el-input v-model="curTiaojian.dxPercent" style="width:213px;" 
                            class="handle-input mr10"></el-input> -->
                        <el-input-number  v-model="curTiaojian.dxPercent" :precision="1" :step="10" :min="0" :max="100" ></el-input-number>
                    </el-form-item>
                    <!-- <el-form-item label="列车占比%" style="margin-left:10px;">
                            <el-input-number  v-model="curTiaojian.lcPercent" :precision="1" :step="10" :min="0" :max="100" ></el-input-number>
                    </el-form-item> -->
                </el-row>
                <el-row v-if="curTiaojian.budget.length>0">
                    <el-form-item v-if="curTiaojian.ledPercent>0" label="LED折扣率">
                        <el-input-number  v-model="curTiaojian.ledDiscount" :precision="1" :step="0.1" :min="2" :max="10" ></el-input-number>
                    </el-form-item>
                    <el-form-item v-if="curTiaojian.dxPercent>0" label="灯箱折扣率" style="margin-left:10px;">
                        <el-input-number v-model="curTiaojian.dxDiscount" :precision="1" :step="0.1" :min="2" :max="10" ></el-input-number>
                    </el-form-item>
                    <el-form-item v-if="curTiaojian.lcPercent>0" label="列车折扣率" style="margin-left:10px;">
                        <el-input-number  v-model="curTiaojian.lcDiscount" :precision="1" :step="0.1" :min="2" :max="10" ></el-input-number>
                    </el-form-item>
                </el-row>
                <el-row  v-if="curTiaojian.budget.length>0">
                    <el-form-item label="投放市场" >
                        <el-cascader :show-all-levels="false" style="width:800px;" placeholder="请选择投放市场" :options="intelligenceAreaList"
                            :props="props" v-model="curTiaojian.stationIdArr" clearable></el-cascader>
                    </el-form-item>                    
                </el-row>
                <el-row  v-if="curTiaojian.stationIdArr.length>0 && curTiaojian.ledPercent>0">
                    <el-form-item label="每日投放频次">                            
                        <el-select v-model="curTiaojian.times"  placeholder="请选择每日投放频次">
                            <el-option
                                v-for="item in timesList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-row>
                <el-row  v-if="curTiaojian.times!='' && curTiaojian.ledPercent>0">
                    <el-form-item  label="版本时长" style="width:372px;">                            
                                <el-select @change="lengthChanged" v-model="curTiaojian.length"  placeholder="请选择版本时长">
                                    <el-option
                                    v-for="item in lengthList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                    </el-form-item>

                </el-row>
                <el-row  v-if="curTiaojian.length!='' || curTiaojian.dxPercent>0 || curTiaojian.lcPercent">
                    <el-form-item label="投放日期">
                        <el-date-picker v-model="curTiaojian.dateArray" type="daterange" range-separator="至"
                            value-format="yyyy/MM/dd HH:mm:ss" start-placeholder="年/月/日" end-placeholder="年/月/日">
                        </el-date-picker>
                    </el-form-item>                              
                </el-row>
                <el-row v-if="curTiaojian.length!='' || curTiaojian.dxPercent>0 || curTiaojian.lcPercent">
                    <el-col :span="8"></el-col>
                    <el-col :span="8">
                        <!-- <el-row>
                            <el-col :span="12"> -->
                                <el-button type="primary" @click="onCalculate">智能运算</el-button>
                            <!-- </el-col> -->
                            <!-- <el-col :span="12">
                                <el-button type="primary" @click="onBack">返回</el-button>
                            </el-col> -->
                        <!-- </el-row> -->
                    </el-col>
                    <el-col :span="8"></el-col>
                </el-row>
            </el-form>
            </div>
            <div v-if="active==1">
                <div>
            <div style="display:flex;justify-content:space-around;">
                <div style="width:95%;line-height:2.0;">
                    <el-card    class="cardstyle" shadow="hover">
                        <div slot="header" class="clearfix">
                            <span><i class="el-icon-s-unfold" style="color:#3B54C5;"></i>投放效果</span>
                            <el-button style="float: right; " type="text"  @click="openSaveDialog">保存方案</el-button>
                        </div>
                        <el-divider style="margin-top:5px;"></el-divider>
                        <el-row>                           
                            <!-- <el-col v-if="curTiaojian.discount<10 " :span="14">折扣费用(万元):</el-col>
                            <el-col v-else :span="14">刊例费用(万元):</el-col> -->
                            <el-col :span="8">费用:</el-col>
                            <el-col :span="16">￥{{curItem.budget}}(万元)<span style="margin-left:50px;">*执行率：<span style="color:red;">{{rate}}</span></span></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-tooltip class="item" effect="dark" placement="right" >
                                    <template v-slot:content> 
                                        <div >
                                            <ul>
                                                <li>即Cost per mill, 是指某一个营销活动，平均获得一千人次的接触度所需要花费的成本，以货币单位表示.</li>
                                                <li>该指标可用于评估广告投放的经济性，评估不同市场的广告成本效益</li>
                                            </ul>
                                        </div>
                                    </template>
                                    <span class="grid-num">CPM:</span>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="16">￥{{curItem.CPM}}(元)</el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-tooltip class="item" effect="dark" placement="left-start" content="某个广告在一定时期内被观众接触的总人次，以千人次表示" >
                                    <span>累计接触度:</span>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="16"><span class="grid-num-small">{{curItem.Frequency}}(千人次)</span></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-tooltip class="item" effect="dark" placement="left-start" >
                                    <template v-slot:content> 
                                        <div >
                                            <ul>
                                                <li>到达率是一个时间上的纵向累积指标，它考察特定时间段内观众收看某一媒体或广告<br/>
                                                    （或某一广告计划所能覆盖）的不重复的人数（或比例），反映了接触的受众规模。</li>
                                                <li>通俗解释到达率(千人)就是看到该广告的总人数</li>
                                            </ul>
                                        </div>
                                    </template>                        
                                    <span>到达率:</span>                        
                                </el-tooltip>
                            </el-col>
                            <el-col :span="16"><span class="grid-num-small">{{curItem.chudarenshu}}(千人)</span></el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-tooltip class="item" effect="dark" placement="left-start"  >
                                    <template v-slot:content> 
                                        <div >
                                            <ul>
                                                <li>一个广告排期每条或总体被观众收看的次数</li>
                                                <li>暴露频次用于广告投放计划传播深度的评估</li>
                                                <li>算法：累计接触度(千人次)除以到达率(千人)</li>
                                            </ul>
                                        </div>
                                    </template>
                                    <span>暴露频次:</span>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="16"><span class="grid-num-small">{{curItem.Reach}}(次)</span></el-col>
                        </el-row>
                    </el-card>
                </div>
                
                <!-- <div>  
                    <el-card class="cardstyle" style="margin-left: 0px; height: 220px;"  shadow="hover">
                        <div slot="header" class="clearfix" style="margin-top:7px;">
                            <span><i class="el-icon-s-unfold" style="color:#3B54C5;"></i>折扣设置</span>                            
                        </div>
                        <el-divider style="margin-top:9px;"></el-divider>
                        <div v-if="cartHaveLed"> LED <span style="color:red;margin-left:2px;">{{curLedDiscount}}</span>折
                            <div>
                                <el-input-number style="width: 135px;margin-top:5px;" v-model="curLedDiscount" :precision="1" :step="0.1" :min="1" :max="10" @change="onSlideChange(1)"></el-input-number>
                            </div>
                        </div>
                        <div v-if="cartHaveDx" style="margin-top:10px;"> 灯箱<span style="color:red;margin-left:2px;">{{curDxDiscount}}</span>折
                            <div>
                                <el-input-number style="width: 135px;margin-top:5px;" v-model="curDxDiscount" :precision="1" :step="0.1" :min="4" :max="10" @change="onSlideChange(2)"></el-input-number>
                            </div>
                        </div>          
                        
                    </el-card>
          
                </div> -->
                
                <div style="width:45%;line-height:2.0;">
                    <el-card   class="cardstyle" style="margin-left: 0px;    height: 220px;" shadow="hover" >
                        <div slot="header" class="clearfix">
                            <span><i class="el-icon-s-unfold" style="color:#3B54C5;"></i>技术卡片</span>                            
                        </div>
                        <el-divider style="margin-top:3px;"></el-divider>
                        <el-row>
                            <el-col :span="6">投放周期:</el-col>
                            <el-col :span="18">{{curTiaojian.dateArray[0]}} 至 {{curTiaojian.dateArray[1]}} </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">折扣:</el-col>
                            <el-col :span="18">
                                <span v-if="curTiaojian.ledPercent>0">LED:<span style="color:red;">{{curTiaojian.ledDiscount}}</span>折&nbsp;&nbsp;</span>
                                <span v-if="curTiaojian.dxPercent>0">灯箱:<span style="color:red;">{{curTiaojian.dxDiscount}}</span>折&nbsp;&nbsp;</span>
                                <span v-if="curTiaojian.lcPercent>0">列车:<span style="color:red;">{{curTiaojian.lcDiscount}}</span>折&nbsp;&nbsp;</span>
                            </el-col>
                        </el-row>
                        <el-row v-if="curTiaojian.ledPercent>0">
                            <el-col :span="6">每日投放频次:</el-col>
                            <el-col :span="18">{{curTiaojian.times}}次</el-col>
                        </el-row>
                        <el-row v-if="curTiaojian.ledPercent>0">
                            <el-col :span="6">时长(秒):</el-col>
                            <el-col :span="18">{{curTiaojian.length}}</el-col>
                        </el-row>
                    </el-card>
                </div>
            </div>
            <!-- <div slot="header" class="clearfix">
                    <el-button style="float: right; " type="text"  @click="onChangeMedia">添加新资源</el-button>
            </div> -->
            <div>
                <el-form ref="form" :model="searchForm" label-width="120px" style="margin-top:20px;" label-position="left">
                    <el-row>
                        <el-col :span="10">
                            <el-form-item label="关键字" >
                                <el-input  v-model="searchForm.key" style="width:200px;" placeholder="可输入地区/车站/位置/套餐关键字进行检索"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="自动重算" >
                                <el-switch
                                v-model="searchForm.auto"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                active-text="是"
                                inactive-text="否"
                                @change="changeAuto">
                                </el-switch>
                            </el-form-item>
                            
                            
                        </el-col>
                        <el-col :span="4">
                                <el-button v-if="!searchForm.auto" type="primary" @click="onCalculateByHand">计算</el-button>
                            </el-col>
                    </el-row>
                </el-form>
            </div>
            </div>
            <div style="margin-top:5px;">
             <!-- :data="mediaList"  -->
                <el-table
                    :data="mediaList.filter(data => !searchForm.key || data.station.toLowerCase().includes(searchForm.key.toLowerCase())
                                             || data.position.toLowerCase().includes(searchForm.key.toLowerCase())
                                             || data.area.toLowerCase().includes(searchForm.key.toLowerCase())
                                             || data.purename.toLowerCase().includes(searchForm.key.toLowerCase()))"
                    border :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            style="width: 100%;" ref="Table">
                    <el-table-column prop="area" width="80px;" align="center" label="操作" >
                        <template v-slot="scope">
                            <el-checkbox v-model="scope.row.selected"  @change="onFreeChange(scope.row)">选用</el-checkbox>                                
                        </template>
                    </el-table-column>
                    <el-table-column prop="area" align="center"  width="110" label="地区" ></el-table-column>
                    <el-table-column prop="station" align="center" label="车站"  width="130">
                        <template v-slot="scope">
                            <el-button type="text" @click="openStation(scope.row.station)">{{scope.row.station}}</el-button>                                    
                        </template>
                    </el-table-column>
                    <el-table-column prop="typeName" align="center"  width="50" label="类型" ></el-table-column>
                    <el-table-column prop="position" align="center"  width="100" label="位置" ></el-table-column>
                    <el-table-column prop="purename" align="center"  width="100" label="套餐名称" ></el-table-column>
                    <!-- <el-table-column prop="typeName" align="center" width="80" label="资源类型" ></el-table-column> -->
                    <el-table-column prop="totalCount" align="center" width="80" label="资源数量" ></el-table-column>
                    <el-table-column prop="medias" align="center"   sortable label="媒体资源(尺寸:m*m 面积:㎡)">
                        <template v-slot="scope">
                            <!-- <div class="group-item" v-for="(item,index) in scope.row.mediaInfo" :key="index">
                                <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="openMedia(item.images)">{{item.mediaCode}}</div>
                                <div v-else>{{item.mediaCode}}</div>
                            </div>                         -->
                            <div class="group-item" v-for="(item,index) in scope.row.mediaInfo" :key="index">
                                <span v-if="scope.row.mediaInfo.length<=5">
                                    <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="openMedia(item.images)">{{item.mediaCode}}</div>
                                    <div v-else>{{item.mediaCode}}</div>
                                </span>
                                <span v-else-if="index<5 || (index>=5 && scope.row.showMore)">
                                    <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="openMedia(item.images)">{{item.mediaCode}}</div>
                                    <div v-else>{{item.mediaCode}}</div>                                    
                                </span>
                            </div>    
                            <div v-if="scope.row.mediaInfo.length>5" style="color:#409EFF">
                                <span v-if="scope.row.showMore" @click="scope.row.showMore=false">收起</span><span v-else  @click="scope.row.showMore=true">更多</span>
                            </div>      
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalCount" align="center" width="80px" label="是否赠送" >
                        <template v-slot="scope">
                            <div v-if="scope.row.canFree==1">
                                <el-checkbox v-model="scope.row.isFree" @change="onFreeChange(scope.row)">是</el-checkbox>        
                            </div>
                            <div v-else>
                                <el-tooltip  class="item" effect="dark" placement="right" content="不可赠送，详情请咨询高铁资源中心。">      
                                    <div style="margin-top: -1px;">不可赠送</div>
                                </el-tooltip>
                            </div>         
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            
            <div>
                <div style="display:flex;justify-content:center;margin-top:15px;">
                    <el-button type="primary" @click="onBack">返回</el-button>
                </div>
            </div>

            <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="viewStationVisible"
                                    append-to-body>
                <div style="height:2800px;">
                    <station @close="viewStationVisible=false" :station="station"/>
                </div>
        </el-dialog>

        <el-dialog title="资源图片" v-model="viewMediaVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                <media-detail @close="viewMediaVisible=false" :image-data="imageList"/>
        </el-dialog>

        <el-dialog title="保存方案" v-model="setSaveDialogVisible" width="40%" @open="open()"
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                        append-to-body>
                        <set-save  @close="setSaveDialogVisible=false" 
                            :total-price="curItem.budget" 
                            :led-discount="curTiaojian.ledDiscount" 
                            :dx-discount="curTiaojian.dxDiscount" 
                            :lc-discount="curTiaojian.lcDiscount" 
                            :smart-result="curItem"
                            :cart-list="saveList" />
        </el-dialog>

        <el-dialog title="选择资源" v-model="chooseMediaDialogVisible" width="60%" 
                    :modal-append-to-body='false'
                    :close-on-click-modal='false'
                    @close='closeAddDialog'
                    append-to-body>
                    <select-media-package ref="selectMediaPackageObj" :show-free="'true'" :group-id="curChangeGroupId" :except-ids="currentUseIds" @change="changeItem"/>
        </el-dialog> 

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getLatestMonthDateArrayByDate, getDateArrayByBoLangXian, getNextMonthDateArray } from "@/utils/time";
import UploadDialog from "@/views/Business/Project/UploadDialog";
import VerListDialog from "@/views/Business/Project/VerListDialog";
import ResultDialog from "@/views/Business/Project/ResultDialog";
import { getToken } from "@/utils/auth";
import { DelProjectSmart, ProjectExportById, SaveProjectSmart } from "@/api/operate";
import MediaDetail from '@/views/Business/Smart/MediaDetail';
import Station from '@/views/Business/Station';
// import SelectProduct from './SelectProduct.vue';
import SetSave from './Smart/SetSave.vue';
import SelectMediaPackage from '@/views/Business/SelectMediaPackage.vue';

export default {
    name: 'baseform',
    components: {
        UploadDialog
        , VerListDialog
        , ResultDialog
        , SelectMediaPackage
        ,MediaDetail,Station
        // ,SelectProduct
        ,SetSave
    },
    computed: {
        ...mapGetters(['SmartSuggest', 'intelligenceAreaList', 'categoryList','CalculateMultiSmart_WithoutBudget'])
    },
    data() {
        return {
            active:0,
            //条件
            curTiaojian:{
                industryId:'',
                budget:'',
                ledPercent:100,
                dxPercent:0,
                lcPercent:0,
                ledDiscount:2,
                dxDiscount:2,
                lcDiscount:2,
                dateArray: getNextMonthDateArray(),                
                stationIdArr: [],
                times:'',
                length:'',
            },
            props: { multiple: true },
            mediaList:[],
            //实际
            curItem:{
                Frequency:'',
                chudarenshu:'',
                Reach:'',
                cpm:'',
                budget:'',
                industryId:'',
            },
            rate:'',
            searchForm:{
                key:'',
                auto:true,
            },

            viewStationVisible:false,
            station:'',
            viewMediaVisible:false,
            setSaveDialogVisible:false,
            saveList:[],

            //添加资源
            chooseMediaDialogVisible:false,
            curChangeGroupId:0,
            currentUseIds:'',
            currentadjLog:'',
            
            lengthList: [{ label: '10秒', value: '10' }, { label: '15秒', value: '15' }],
            timesList: [{ label: '100次', value: 100 }, { label: '200次', value: 200 }, { label: '300次', value: 300 }, { label: '500次', value: 500 },
            { label: '1000次', value: 1000 }],
        };
    },

    async mounted() {
        await this.$store.dispatch('operate/intelligenceAreaList');
        await this.$store.dispatch('operate/categoryList');
        // await this.$store.dispatch('operate/productList');

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'http://pv.sohu.com/cityjson?ie=utf-8&ver=' + Math.random();
        document.body.appendChild(s);

    },

    // watch:{
    //     curItem.ledDiscount:
    // }

    methods: {
        async onCalculate() {
            var Ip = '';
            var cityname = '';
            try {
                Ip = returnCitySN['cip'];
                cityname = returnCitySN['cname']
            } catch (e) {
            // 错误处理代码片段
            }
            // if(returnCitySN){
            //     Ip = returnCitySN['cip'];
            //     cityname = returnCitySN['cname']
            // }
            localStorage.setItem('Ip', Ip)
            localStorage.setItem('cityName', cityname)

            // const loading = this.$loading({
            //     lock: true,
            //     text: '智能计算中...',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.7)'
            // });

            var ip = localStorage.getItem('Ip');
            var cityName = localStorage.getItem('cityName');
            const {budget,ledPercent,ledDiscount,dxPercent,dxDiscount,lcPercent,lcDiscount,stationIdArr,times,length,dateArray: [sDate, eDate]}= this.curTiaojian;
            if (ledPercent+dxPercent+lcPercent!==100){
                this.$message({message: '投放占比总和不为100%，请调整！'});
                return;
            }
            console.log('aa',stationIdArr);
            if (stationIdArr.length<1){
                this.$message({message: '请选择投放市场'});
                return;
            }
            if (ledPercent>0 && (!times || !length )){
                this.$message({message: '请选择每日投放频次和版本时长'});
                return;
            }
            var tagIds = '';
            stationIdArr.forEach(ele => {
                if (ele.length==2){
                    if(tagIds==''){
                        tagIds=ele[1];
                    }else{
                        tagIds=tagIds+','+ele[1];
                    }                    
                }else{
                    if(tagIds==''){
                        tagIds=ele[2];
                    }else{
                        tagIds=tagIds+','+ele[2];
                    }  
                }
            });
            // return;
            // var tagIds = stationIdArr.join(',')
            // await this.$store.dispatch('operate/CalculateMultiSmart',{ip,cityName,condi}).then(res=>{
            // await this.$store.dispatch('operate/SmartSuggest', { budget,discount,tagIds,times,length,sDate,eDate,ip,cityName}).then(res => {
            await this.$store.dispatch('operate/SmartSuggestNew', { budget,ledPercent,ledDiscount,dxPercent,dxDiscount,lcPercent,lcDiscount,tagIds,times,length,sDate,eDate,ip,cityName}).then(res => {
                // loading.close();
                // console.log('SmartSuggest',this.SmartSuggest)
                if (this.SmartSuggest && this.SmartSuggest[0] && this.SmartSuggest[0].length>0){
                    // console.log('aa');
                    this.active=1;
                    this.mediaList=this.SmartSuggest[0];
                    // console.log('bb');
                }else{
                    this.$message({message: '遗憾，无法找到满足您所设置条件的资源，请重试！'});
                    return;
                }
                // console.log('cc',this.SmartSuggest[2]);
                this.curItem.Frequency = this.SmartSuggest[2][0].Frequency;
                this.curItem.chudarenshu = this.SmartSuggest[2][0].chudarenshu;
                this.curItem.Reach = this.SmartSuggest[2][0].Reach;
                this.curItem.CPM = this.SmartSuggest[2][0].CPM;
                this.curItem.budget = this.SmartSuggest[1][0].totalUse;
                
                this.rate = Number(this.curItem.budget*100/this.curTiaojian.budget).toFixed(2)+'%';
                console.log('dd',this.curItem);
            });
            // }) 
        },

        openSaveDialog(){
            this.saveList=[];       
            // const{sDate,eDate,times,length}=this.curItem;
            const {times,length,dateArray: [sDate, eDate]}= this.curTiaojian;
            this.mediaList.forEach(ele => {
                if (ele.selected==true){
                    var tmp={};
                    // tmp.detailId=-1;
                    tmp.sDate=sDate;
                    tmp.eDate=eDate;
                    tmp.groupId=ele.groupId;
                    tmp.groupLevel=1;                
                    tmp.times=times;
                    tmp.length=length;
                    if (ele.isFree){
                        tmp.totalPrice=0;
                    }
                    else{
                        tmp.totalPrice=-1;
                    }
                    tmp.haveSelectListIds='';
                    tmp.adjLog='';
                    tmp.freeIds='';

                    var obj={};
                    obj.count=1;
                    obj.groupId=ele.groupId;
                    obj.detailId=-1;
                    obj.item=tmp;
                    obj.weaponId=0;
                    this.saveList.push(obj);
                }

            });
            if (this.saveList.length==0){
                this.$message({message: '未选中资源，无法保存！'});
                    return;
            }
            this.curItem.industryId = this.curTiaojian.industryId;
            this.setSaveDialogVisible=true;
        },

        onBack(){
            this.active=0;
        },

        openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },

        openMedia(items){
            this.imageList=items;
            this.viewMediaVisible=true;
        },

        onFreeChange(item){
            // // console.log("switch",this.productList);
            // // this.checkMediaType();
            // if (this.searchForm.auto){
            //     this.changeCalculate();
            // }
            if (this.searchForm.auto){
                this.changeCalculate();
            }else{
                this.changed=true;
            }
            
        },

        changeAuto(){
            if (this.searchForm.auto){
                this.changeCalculate();
            }
        },

        onCalculateByHand(){
            this.changeCalculate();
        },

        async changeCalculate(){
            // const loading = this.$loading({
            //     lock: true,
            //     text: '智能计算中...',
            //     spinner: 'el-icon-loading',
            //     background: 'rgba(0, 0, 0, 0.7)'
            // });

            const {budget,discount,stationIdArr,times,length,dateArray: [sDate, eDate]}= this.curTiaojian;
            // const{sDate,eDate,times,length}=this.transferCondition;
            console.log('curTiaojian',this.curTiaojian);
            var calcuList=[];
            this.mediaList.forEach(ele => {
                // ele.detailId=-1;
                if (ele.selected==true){
                    var tmp={}
                    tmp.sDate=sDate,
                    tmp.eDate=eDate,
                    tmp.times=times;
                    tmp.length=length;
                    tmp.groupId=ele.groupId;

                    tmp.isFree=ele.isFree?ele.isFree:0;//0;//
                    // ele.groupLevel=ele.groupLevel;
                    // ele.totalPrice=0;
                    // ele.adjLog='';
                    calcuList.push(tmp);
                }
            });
            var condi = JSON.stringify(calcuList);

            // console.log('condi',condi)
            var ip = localStorage.getItem('Ip');
            var cityName = localStorage.getItem('cityName');
            
            await this.$store.dispatch('operate/CalculateMultiSmart_WithoutBudget'
                ,{ip,cityName,ledDiscount:this.curTiaojian.ledDiscount,dxDiscount:this.curTiaojian.dxDiscount,lcDiscount:this.curTiaojian.lcDiscount,condi}).then(res=>{
                    //
                    this.resultData=this.CalculateMultiSmart_WithoutBudget;                     
                    // this.budget = this.resultData.feiyong;
                    this.curItem.Frequency = this.resultData.Frequency;
                    this.curItem.chudarenshu = this.resultData.chudarenshu;//SmartSuggest[2][0].chudarenshu;
                    this.curItem.Reach = this.resultData.Reach;
                    this.curItem.CPM = this.resultData.CPM;
                    this.curItem.budget = this.resultData.feiyong;
                    this.rate = Number(this.curItem.budget*100/this.curTiaojian.budget).toFixed(2)+'%';
                    // loading.close();
                    // this.viewMediaVisible=true;
                });

            // await this.$store.dispatch('operate/CalculateMultiSmart',{ip,cityName,condi}).then(res=>{
            //     loading.close();
            // });
        },
      
        
        getRowKey(row) {
            return row.projectId;
        },

        onChangeMedia(){
            this.curChangeGroupId = 0;            
            this.getCurrentUseIds();
            
            // console.log('currentUseIds',this.currentUseIds)
            this.chooseMediaDialogVisible=true;
        },

        //获得已使用的套餐包
        getCurrentUseIds(){
            this.currentUseIds='';
            this.currentadjLog='';
            this.mediaList.forEach(element => {

                if (this.currentUseIds==''){
                    this.currentUseIds = element.groupId;
                }else{
                    this.currentUseIds = this.currentUseIds+','+element.groupId;
                }
            });
            console.log('this.currentUseIds',this.currentUseIds)
        },

        handleSelectionChange(val) {
            this.selectedList = val;
            // this.SelectEdEmit();
        },

        // 每页多少条
        handleSizeChange(val) {
            this.pageSize = val;
        },
        handleppmlSizeChange(val) {
            this.ppml.pageSize = val;
        },
        // 当前页
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        handleppmlCurrentChange(val) {
            this.ppml.currentPage = val;
        },



        open() {
            // console.log('do open')
            this.$nextTick(() => {
                //  执行echarts方法

            })
        },
    }
};
</script>

<style scoped>
</style>